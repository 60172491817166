import { RoleTypes } from "../Constant/enums/roleTypes";

const METHODS = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PATCH: "PATCH",
  PUT: "PUT"
};

export const register = { url: "/auth/register", method: METHODS.POST };

export const login = { url: "/auth/login", method: METHODS.POST };

export const authGooglelogin = {
  url: "/auth/login/with-google-auth",
  method: METHODS.POST,
};

export const resetPassword = {
  url: "/auth/reset-password/{{token}}",
  method: METHODS.POST,
};

export const changePassword = {
  url: "/auth/change-password",
  method: METHODS.PATCH
}

export const forgotPassword = {
  url: "/auth/forgot-password",
  method: METHODS.POST,
};

export const createPost = { url: "/feeds/createFeed", method: METHODS.POST };

export const updatePost = { url: "/feeds/{{postId}}", method: METHODS.PATCH };

export const get_suscribers = { url: "/subscribe/user/{{userid}}", method: METHODS.GET };

export const myFeed = {
  url: "/feeds/feedsWithPagination",
  method: METHODS.GET,
};

export const postDelete = {
  url: "/feeds/{{postId}}",
  method: METHODS.DELETE,
}

export const exploreList = {
  url: `/users/queryUsersWithPagination`,
  method: METHODS.GET,
};

export const updateUser = { url: "/users", method: METHODS.PATCH };

export const userDetails = { url: "/users", method: METHODS.GET };

export const fileUpload = { url: "/files/upload", method: METHODS.POST };

export const createOrder = { url: "/payment/new-order", method: METHODS.POST };

export const createNewComment = {
  url: "/comments/create",
  method: METHODS.POST,
};

export const linkHandler = { url: "/likes/likeUnlike", method: METHODS.POST };

export const subscribeHandler = {
  url: "/subscribe/create",
  method: METHODS.POST,
};

export const authWithGoogle = {
  url: "/auth/google-auth",
  method: METHODS.POST,
};

export const getMessages = {
  url: "/message/list",
  method: METHODS.GET
}

export const newMessage = {
  url: "/messages",
  method: METHODS.POST
}

export const getMemberShipDetails = {
  url: "/membership/{{creatorId}}",
  method: METHODS.GET
}

export const updateMemberShipDetails = {
  url: "/membership/update",
  method: METHODS.PUT
}

export const requestPayout = {
  url: "/payment/request-payout",
  method: METHODS.POST
}

export const requestedPayoutDetails = {
  url: "/payment/requested-payout-status",
  method: METHODS.GET
}