import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as interFace from "../../Config/interface.config";
import APIRequest from "../../API";
import * as PATH from "../../API/path";
import toast from "react-hot-toast";

const initialState: interFace.CreatorSettingsInitialState = {
  memberDetails: {
    messagePrice: "29",
    monthlyPrice: "20",
  },
  memberIncomeStatistic: {
    totalMessageOrders: 0,
    totalMessageOrdersLast30Days: 0,
    totalSubscribeOrders: 0,
    totalSubscribeOrdersLast30Days: 0,
    totalSubscribers: 0,
    totalEarnings: 0,
    totalPayouts: 0
  },
  loading: false,
  memberStatistic: {
    numberOfPosts: 0,
    totalComments: 0,
    totalLikes: 0,
  },
  requestedPayoutAmount: 0,
  requestedPayoutState: '',
  hadRequestedRequest: false
};

export const updateMemberShipDetails = createAsyncThunk<
  any,
  interFace.MemberDetails
>(
  "/membership/update-details",
  async (membershipDetails, { rejectWithValue }) => {
    try {
      toast.loading("updating...");

      const { data }: any = await APIRequest(
        PATH.updateMemberShipDetails,
        membershipDetails
      );

      toast.remove();
      toast.success("Details updated successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

export const fetchMemberShipDetails = createAsyncThunk<any, any>(
  "/user/membership",
  async (creatorId) => {
    try {
      toast.loading("fetching details..");
      let path = { ...PATH.getMemberShipDetails };

      path.url = path.url.replace("{{creatorId}}", creatorId);
      const { data }: any = await APIRequest(path);

      toast.remove();
      return data;
    } catch (error) {}
  }
);

export const requestPayout = createAsyncThunk<any, interFace.RequestPayout>(
  "/request-payout",
  async (requestPayoutDetails, { rejectWithValue }) => {
    try {
      toast.loading("updating...");

      const { data }: any = await APIRequest(
        PATH.requestPayout,
        requestPayoutDetails
      );

      toast.remove();
      toast.success("Requested successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

export const fetchRequestedPayoutDetails = createAsyncThunk(
  "/requested/request-payout",
  async () => {
    try {
      const { data }: any = await APIRequest(PATH.requestedPayoutDetails);
      return data;
    } catch (error: any) {}
  }
);

const settingSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    updatePricing: (
      state,
      {
        payload,
      }: PayloadAction<{
        paramKey: keyof interFace.CreatorSettingsResponse;
        pricingKey: "monthlyPrice" | "messagePrice";
        priceValue: string;
      }>
    ) => {
      const { paramKey, priceValue, pricingKey } = payload;
      if (paramKey === "memberDetails") {
        state[paramKey][pricingKey] = priceValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMemberShipDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      fetchMemberShipDetails.fulfilled,
      (
        state,
        { payload }: PayloadAction<interFace.CreatorSettingsResponse>
      ) => {
        Object.assign(state, payload);
        state.loading = false;
      }
    );

    builder.addCase(fetchMemberShipDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchRequestedPayoutDetails.fulfilled, (state, { payload }) => {
      state.requestedPayoutAmount = payload?.data?.amount;
      state.requestedPayoutState = payload?.data?.status;
      state.hadRequestedRequest = payload?.data?.amount && payload?.data?.amount > 0 ? true : false;
      state.loading = false;
    });

    builder.addCase(fetchRequestedPayoutDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchRequestedPayoutDetails.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { updatePricing } = settingSlice.actions;
export default settingSlice.reducer;
