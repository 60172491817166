import styled from "styled-components";

export const Payouts = styled.div`
  width: 800px;
  max-width: 90%;
  margin: 0 auto;

  h1 {
    margin: 4rem 0;
    font-size: 2rem;
  }
  margin-bottom: 30px
`;

export const CommonInput = styled.input`
  width: 100%;
  height: 3rem;
  background: transparent;
  outline: none;
  border: 1px solid #8f8f8f;
  border-radius: 20px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-align: center;
`;