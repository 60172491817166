import { useEffect, useRef, useState } from "react";
import * as P from "./style";
import { PiPlayCircle } from "react-icons/pi";

interface Props {
  url: string;
}

const VideoPlayer = ({ url }: Props) => {
  console.log("URL", url);
  const videoRef = useRef<HTMLVideoElement>(null); // Create a ref for the video element
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const handlePlay = (flag?: boolean) => {
    try {
      if (videoRef.current) {
        videoRef.current.defaultMuted = true;
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
        if (!flag) setIsPlaying(!isPlaying);
      }
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      if (videoRef.current) videoRef.current.pause();
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.warn("Autoplay blocked on mobile, user interaction needed.");
        });
      }
    }
  }, []);

  return (
    <>
      <div
        className="w-full items-center justify-center"
        style={{
          height: "99%",
          background: "black",
          border: "2px solid white",
          borderRadius: "10px",
          position: "relative",
          zIndex: 201,
          display: !error ? "none" : "flex",
        }}
      >
        <p className="text-white">{errorMessage}</p>
      </div>

      <P.PostVideo
        style={{ display: error ? "none" : "block" }}
        ref={videoRef}
        crossOrigin="anonymous"
        controls
        controlsList="nodownload"
        autoPlay
        muted
        loop
        onPlay={() => handlePlay()}
        onPause={() => handlePlay()}
        onError={(event) => {
          const videoElement = videoRef.current;
        
          if (videoElement && videoElement.error) {
            const error = videoElement.error;
        
            setErrorMessage(
              `Video Error Code: ${error.code}, Message: ${
                error.message || "No specific message"
              }`
            );
        
            switch (error.code) {
              case MediaError.MEDIA_ERR_ABORTED:
                setErrorMessage("Video playback was aborted by the user.");
                break;
              case MediaError.MEDIA_ERR_NETWORK:
                setErrorMessage("A network error caused the video download to fail.");
                break;
              case MediaError.MEDIA_ERR_DECODE:
                setErrorMessage(
                  "The video playback was aborted due to corruption or unsupported features."
                );
                break;
              case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                setErrorMessage(
                  "The video could not be loaded due to unsupported format or server issues."
                );
                break;
              default:
                setErrorMessage("An unknown error occurred during video playback.");
            }
          } else {
            setErrorMessage("An unknown error occurred.");
          }
        
          // Hide video, show error message
          setError(true);
        }}
        
      >
        {/* <source src={url} type="video/mp4"></source> */}
        <source src={url} type="video/mp4" />
        <source src={url} type="video/webm" />
        <source src={url} type="video/ogg" />
      </P.PostVideo>

      {!isPlaying && (
        <PiPlayCircle onClick={() => handlePlay(true)} className="play-icon" />
      )}
    </>
  );
};

export default VideoPlayer;
