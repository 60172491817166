import type { GetProp, UploadFile, UploadProps } from "antd";
import APIRequest from "../API";
import * as APIPath from "../API/path";
import * as InterFace from "./interface.config";
import axios from "axios";
import toast from "react-hot-toast";
import { SubscribeToDetails } from "./interface.config";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const getSessionToken: () => string = () =>
  localStorage.getItem("auth-access") ?? "";

export const updateToken = (access: string, refresh: string) => {
  localStorage.setItem("auth-access", access);
  localStorage.setItem("refresh-access", refresh);
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "";
};

export const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const generatePassword = (length: number) => {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const specialChars = "!@#$%^&*()_+{}[]<>?";

  // Initialize the password with at least one lowercase letter, one uppercase letter, and one number
  let password =
    lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
  password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];

  // Fill the rest of the password with random characters
  const remainingLength = length - 3; // Subtract 3 because we've already added one lowercase letter, one uppercase letter, and one number
  const charset = lowercaseChars + uppercaseChars + numbers + specialChars;
  for (let i = 0; i < remainingLength; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  // Shuffle the characters in the password
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return password;
};

export const isCreatorRole = (role: string | null | undefined) => {
  return role === process.env.REACT_APP_CREATOR_ROLE_ID;
};

export const scrollToBottom = (element_id: string) => {
  // Get a reference to the div element, ensuring it's an HTMLDivElement
  const element = document.getElementById(element_id) as HTMLDivElement;
  if (!element) return;
  element.scrollTop = element.scrollHeight;
  element.scrollIntoView({ behavior: "smooth" });
};

export const postAndCommentLinkHandler = async ({
  post,
  comment,
  likedBy,
}: InterFace.LikeInteface) => {
  try {
    let payload: InterFace.LikeInteface = {
      likedBy,
    };
    if (post) payload.post = post;
    if (comment) payload.comment = comment;

    const resp: any = await APIRequest(APIPath.linkHandler, payload);
    return resp.data;
  } catch (error) {
    return error;
  }
};

export const isFileVideo = (name: string) => {
  const fileExt = "." + name.split(".").slice(-1)[0];
  const videoExtensions = [
    ".mp4",
    ".avi",
    ".mkv",
    ".mov",
    ".wmv",
    ".flv",
    ".webm",
    ".ogg",
  ];

  return videoExtensions.includes(fileExt);
};

export const googleAccountDetails = async (token: string) => {
  try {
    const { data } = await axios.get(
      `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const DEFAULT_PRICING = 30;

export const FANXOBILLPHONENUMBER = "9582327860";

export const getToken = () => localStorage.getItem("auth-access") ?? null;

export const checkIsSubscribe = (
  creatorId: string,
  loggedInUserSubscribeArray: SubscribeToDetails[]
) => {
  if (loggedInUserSubscribeArray && loggedInUserSubscribeArray.length > 0) {
    const findOne = loggedInUserSubscribeArray.find((item) => {
      return item._id === creatorId;
    });
    if (findOne) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
