import toast from "react-hot-toast";
import APIRequest from "../API";

export const Capitalize = (word: string | undefined): string =>
  `${word ? `${word[0].toUpperCase()}${word.slice(1)}` : ""}`;

export const copyUserProfileLink = (username: string) => {
  const link = `${window.location.origin}/profile/${username}`;
  navigator.clipboard
    .writeText(link)
    .then(() => {
      toast.success("Profile Link Copied");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export const fetchUserByUserName = async (username: string) => {
  try {
    const url = `/users/fanxo/${username}`;
    const { data }: any = await APIRequest({ method: "GET", url });
    return data ?? null;
  } catch (error) {
    return null;
  }
};

export const messageScrollHandler = () => {
  setTimeout(() => {
    var message_container = document.getElementById("message-container");
    if (message_container)
      message_container.scrollTop = message_container.scrollHeight;
  }, 100)
}

export const formatIndianNumber = (amount: number) =>  {
  // Convert the amount to a string and split into integer and decimal parts
  let [integerPart, decimalPart] = amount.toString().split('.');

  // Format the integer part
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  
  if (otherDigits) {
      lastThreeDigits = ',' + lastThreeDigits; // Add comma before last three digits
  }

  // Insert commas in the remaining part of the integer
  let formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  // Combine the formatted integer part with the decimal part (if any)
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

