import React from "react";
import * as P from "./style";
import DefaultUserImg from "../../Assets/Images/user-profile.png";
import { useNavigate } from "react-router-dom";
import { FeedPosts, UserDetails } from "../../Config/interface.config";
import PostAction from "./PostAction";
import { isCreatorRole } from "../../Config/common.config";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

interface Props {
  post?: FeedPosts;
  userImage?: string;
}

const PostHeader: React.FC<Props> = ({ post, userImage }: Props) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);

  const getCreatorImage = () => {
    if (typeof post?.creator?.image?.fileURL === "string")
      return post?.creator?.image?.fileURL;
    return DefaultUserImg;
  };

  return (
    <P.Header className="cursor-pointer">
      <div
        className="flex cursor-pointer gap-3"
        onClick={() => navigate(`/profile/${post?.creator.username}`)}
      >
        <P.UserImage
          src={userImage ?? getCreatorImage()}
          alt="user"
          crossOrigin="anonymous"
        />
        <div>
          <P.UserTitle>{post?.creator.fullname}</P.UserTitle>
          <P.UserName to={`/profile/${post?.creator.username}`}>
            @{post?.creator.username ?? ""}
          </P.UserName>
        </div>
      </div>

      <div>{user.id === (post?.creator.id ?? post?.creator._id) && <PostAction post={post} />}</div>
    </P.Header>
  );
};

export default PostHeader;
