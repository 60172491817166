import * as S from "./styles";

const TermsAndConditions = () => {
  return (
    <S.TermsAndConditionWrapper>
      <S.Container>
        <S.Contain>
          <h1>Terms and Conditions</h1>
          <div className="text-white">FANXO PLATFORM TERMS OF SERVICE</div>
          <div className="text-white">Effective Date: April 18, 2024</div>
          <div className="text-white mt-2 font-thin">
            Welcome to the Fanxo Platform, provided by Mave Technologies (A self
            propietorship) ('Fanxo,' 'we,' 'us'). By accessing or using our
            website or mobile applications ('Services'), you agree to be bound
            by these Terms of Service. These Terms establish your rights and
            responsibilities as a user of our Services.
          </div>
          <div className="text-white mt-2 font-thin">
            You must be 16 years or older to create an account or use our
            Services. Users aged less than 16 may participate with parental
            consent. By using the Services, you confirm your ability to form a
            legally binding contract. If you do not agree, do not use the
            Services.
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo is a platform that facilitates the sale of both digital and
            physical goods by third-party sellers. Fanxo is not responsible for
            goods or services sold unless otherwise specified. Any disputes
            between buyers and sellers are not the responsibility of Fanxo.
          </div>
          <div className="text-white my-2 font-semibold">
            1. Content Guidelines & Responsibilities
          </div>
          <div className="text-white">USER CONTENT AND PROHIBITED USE</div>
          <div className="text-white mt-2 font-thin">
            You are solely responsible for the content you post or upload. This
            includes ensuring that your content does not infringe on
            intellectual property, privacy, or publicity rights. Users must
            follow community guidelines as well as content guidelines, which
            prohibit content that is defamatory, harassing, fraudulent, or
            otherwise unlawful.
          </div>
          <div className="text-white mt-2 font-thin">
            Content Restrictions: - No impersonation or use of unauthorized
            private/confidential information. - Do not upload or transmit
            content containing viruses, harmful software, or engage in spamming.
            - All content must be original or licensed, and you must have rights
            to post it.
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo reserves the right to monitor and remove content that violates
            these guidelines, but has no obligation to pre-screen content.
            Violations may result in content removal or account suspension.
          </div>
          <div className="text-white mt-2 font-thin">
            Content Leaks: Leaking restricted or exclusive content is strictly
            prohibited. Penalties may include fines of Rs3500 per leaked
            instance, with additional penalties for failure to remove leaked
            content.
          </div>
          <div className="text-white mt-2 font-thin">
            License to Your Content: By posting content, you grant Fanxo a
            non-exclusive, royalty-free license to use, display, and distribute
            your content for platform operations.
          </div>
          <div className="text-white my-2 font-semibold">
            2. Account Registration & User Responsibilities
          </div>
          <div className="text-white">ACCOUNT REGISTRATION</div>
          <div className="text-white mt-2 font-thin">
            To access certain features of the Services, users must register and
            provide accurate information. Users are responsible for maintaining
            their account and passwords. Multiple accounts are not permitted
            without prior approval.
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo may request additional information to comply with legal
            requirements, including anti-money laundering laws. Failure to
            provide accurate information may result in account suspension or
            termination.
          </div>
          <div className="text-white my-2 font-semibold">
            3. Platform Services and Memberships
          </div>
          <div className="text-white">PLATFORM AND MEMBERSHIPS</div>
          <div className="text-white mt-2 font-thin">
            Fanxo’s Services include access to premium content, memberships, and
            exclusive communities. Memberships may provide access to digital
            content and other benefits. All transactions for memberships are
            between the buyer and seller, and Fanxo is not responsible for the
            delivery or quality of the goods and services offered unless Fanxo
            is the seller.
          </div>
          <div className="text-white mt-2 font-thin">
            Types of Memberships: - Membership monthly: May renew automatically
            or have to be renewed manually unless canceled. - Super Memberships:
            Provide time-limited access to exclusive content.
          </div>
          <div className="text-white my-2 font-semibold">
            4. Payment Terms & Fees
          </div>
          <div className="text-white">FEES AND PAYMENT TERMS</div>
          <div className="text-white mt-2 font-thin">
            Users are responsible for all charges incurred on their accounts.
            Payments must be made in Indian National Rupees unless otherwise
            specified, and payment processing is managed by third-party
            processors. Fanxo may change its pricing and fees without prior
            notice. Taxes on transactions are the user’s responsibility, and
            Fanxo will not calculate or pay taxes on behalf of users.
          </div>
          <div className="text-white my-2 font-semibold">
            5. Legal Compliance & Disclaimer
          </div>
          <div className="text-white mt-2">DISCLAIMER OF WARRANTIES</div>
          <div className="text-white mt-2 font-thin">
            The Services are provided on an 'as-is' and 'as available' basis.
            Fanxo disclaims any warranties regarding the reliability, accuracy,
            or security of the Services. Fanxo is not responsible for any losses
            due to user error, unauthorized access, or service interruptions.
          </div>
          <div className="text-white mt-2">LIMITATION OF LIABILITY</div>
          <div className="text-white mt-2 font-thin">
            Fanxo is not liable for any damages, including lost profits or data,
            arising from the use of the Services. Certain jurisdictions do not
            allow the limitation of liability, so some exclusions may not apply.
          </div>
          <div className="text-white mt-2">INDEMNIFICATION</div>
          <div className="text-white mt-2 font-thin">
            Users agree to indemnify Fanxo for any losses, damages, or claims
            resulting from their use of the Services, violations of these Terms,
            or infringement of third-party rights.
          </div>
          <div className="text-white my-2 font-semibold">
            6. Termination & Governing Law
          </div>
          <div className="text-white">TERMINATION</div>
          <div className="text-white mt-2 font-thin">
            Fanxo reserves the right to terminate access to the Services for any
            violation of these Terms or for any unlawful or inappropriate
            behavior. Upon termination, all rights granted to the user will
            cease, and content may be deleted from the platform without notice.
          </div>
          <div className="text-white mt-2">GOVERNING LAW</div>
          <div className="text-white mt-2 font-thin">
            These Terms are governed by the laws of the Republic of India. Users
            accessing the Services from outside India are responsible for
            complying with local laws.
          </div>
        </S.Contain>
      </S.Container>
    </S.TermsAndConditionWrapper>
  );
};

export default TermsAndConditions;
