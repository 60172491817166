import styled from "styled-components";

export const Pricing = styled.div`
  width: 800px;
  max-width: 90%;
  margin: 0 auto;

  h1 {
    margin: 4rem 0;
    font-size: 2rem;
  }

  .input-range__slider {
    background: white;
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -14px;
    border: none;
    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
  }

  /* .input-range__track { */
  .input-range__track.input-range__track--background {
    background: linear-gradient(90deg, #176ac1 0%, #ad7ac6 52%, #f18d25 100%);
  }
  .input-range__track--active {
    background: transparent !important;
  }

  .input-range {
    width: calc(100% - 28px);
    margin: auto;
  }

  .input-range__label-container {
    color: white;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    &::before {
      content: "₹";
    }
  }

  .input-range__label.input-range__label--max {
    top: 25px;
    /* right: 15px; */
  }

  .input-range__label.input-range__label--min {
    /* left: 15px; */
    top: 25px;
  }

  .input-range__label--value {
    top: -45px;
    left: 2px;
  }

  @media screen and (max-width: 768px){
    margin-bottom: 40px;
  }
`;
