import styled from "styled-components";
import { CommonButton, CommonIonButton } from "../../common-styles";

export const Details = styled.div`
  margin-top: 1rem;
  position: relative;
  height: 300px;
`;

export const ProfileBackgroundImage = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

export const SubscribeBtn = styled(CommonIonButton)`
  z-index: 5;
  border: none;
  background: linear-gradient(90deg, #c1effe 0%, #d5c2fd 51.5%, #e9b9fe 100%);
  padding: 0.8rem 1rem;
  border-radius: 50px;
  width: unset;
  box-shadow: 0px 0px 7px 2px #00000040;
  span {
    font-size: 15px;
    font-weight: 600;
    color: black;
  }
`;

export const DetailsFooter = styled.div`
  position: absolute;
  width: calc(100% - 2rem);
  bottom: -1.2rem;
  left: 1rem;
`;

export const QuickAction = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: linear-gradient(90deg, #c1effe 0%, #d5c2fd 51.5%, #e9b9fe 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 7px 2px #00000040;
`;

export const CountDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 1rem;
  border-right: 1px solid black;
  &:last-child {
    padding-right: 0;
    border: 0;
  }
`;

export const ProfileImage = styled.img`
  width: 130px;
  height: 130px;
  min-width: 130px;
  min-height: 130px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  object-fit: cover;
  background: white;
  @media screen and (max-width: 425px) {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
`;

export const ProfileContent = styled.img``;

export const ProfileHeaderContainer = styled.div`
  max-width: 800px;
  padding: 1rem;
  margin: auto;
  .section-one {
    width: 100%;
    height: 300px;
    margin-top: 0.6rem;
    border-radius: 10px;
    position: relative;
    background: linear-gradient(
      91.66deg,
      #bda0cb 4.78%,
      #a596cb 47.99%,
      #837fcb 98.72%
    );
    /* display: flex; */
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .section-two {
    max-width: 750px;
    width: 95%;
    margin: auto;
    span {
      position: relative;
      top: 8px;
    }
  }

  .section-three {
    margin-bottom: 3rem;
    font-weight: 1.1rem;
    textarea {
      border: none;
      outline: none;
      box-shadow: none !important;
      background-color: black;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      &::active {
        box-shadow: none !important;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .section-one {
      height: unset;
      aspect-ratio: 5/3;
    }
  }
`;

export const JoinBtn = styled.button`
 cursor: pointer;
  flex: none;
  background: linear-gradient(90deg, #DB77E5 0%, #7A5BC9 48.5%, #4B4DBB 100%);
  color: white;
  padding: .8rem .9rem;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: .5rem;
`