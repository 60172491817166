import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import * as M from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { createNewPost, updatePost } from "../Redux/slices/postSlice";
import { AppDispatch, RootState } from "../Redux/store";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DefaultUserImg from "../Assets/Images/user-profile.png";
import StepOne from "../Components/Post/CreatePost/StepOne";
import { FaArrowLeft } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import { CommonButton } from "../common-styles";
import { EditPostPayload, FeedPosts } from "../Config/interface.config";
import { getUserDetails } from "../Redux/slices/userSlice";

interface Props {
  onHide: () => void;
  show: boolean;
  editPost?: FeedPosts | undefined;
  isIntro?: boolean;
}

interface NewPostFormData {
  description: string;
}

const CreatePostModal: React.FC<Props> = ({
  onHide,
  show,
  editPost,
  isIntro = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [fileList, setFileList] = useState<
    { fileURL: string; fileId: string }[]
  >([]);
  const {
    image,
    id: user_id,
    username,
  } = useSelector((state: RootState) => state.user);
  const [step, setStep] = useState<number>(1);

  const initialValues: NewPostFormData = {
    description: editPost?.text ?? "",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .required("Text is required")
      .max(2000, "Caption cannot be more than 2000 characters"),
  });

  const resetFormHandler = () => {
    setFileList([]);
    setStep(1);
  };

  // Handle form submission
  const handleSubmit = (values: NewPostFormData, { setSubmitting }: any) => {
    if (!user_id || typeof user_id !== "string") {
      toast.error("Invalid user.");
      return;
    }
    if (editPost?._id) {
      const postData: EditPostPayload = {
        text: values.description,
        files: editPost.files.map((file) => file.fileURL),
        likeCount: editPost.likeCount.map((lk) => lk._id),
        comments: editPost.comments,
        tips: editPost.tips,
        _id: editPost._id,
      };

      dispatch(updatePost(postData)).then(() => {
        onHide();
        if (isIntro) {
          dispatch(getUserDetails());
        }
      });
    } else {
      const postData = {
        text: values.description,
        creator: user_id,
        files: fileList.map((file) => file.fileId),
        likeCount: [],
        comments: [],
        tips: [],
        isIntro: isIntro,
      };
      dispatch(createNewPost(postData)).then(() => {
        onHide();
        if (isIntro) {
          dispatch(getUserDetails());
        }
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (!!editPost?.text) setStep(2);
  }, [editPost?.text, show]);

  return (
    <M.DragAndDrop>
      <Modal
        footer={false}
        className="create-post-modal"
        closeIcon={false}
        width={step === 1 ? 1000 : 600}
        open={show}
        onCancel={onHide}
        destroyOnClose
        afterClose={() => resetFormHandler()}
      >
        <div className=" w-full rounded-[15px] bg-gradient-to-b from-[#146BC3] via-[#B77BC6] to-[#F48E1D] p-0.5 overflow-hidden">
          <div className="flex h-full w-full items-center justify-center bg-black rounded-[15px]  overflow-hidden">
            <div className="flex-1">
              <M.Header>
                <h1 className="mb-0">
                  {editPost?._id
                    ? "Update"
                    : isIntro
                    ? "Create Intro"
                    : "Add New"}{" "}
                  Post
                </h1>
                {!editPost?._id && (
                  <FaArrowLeft
                    size={20}
                    className="absolute top-[12px] left-[15px] text-white cursor-pointer"
                    onClick={() => (step === 1 ? onHide() : setStep(1))}
                  />
                )}
              </M.Header>

              <div>
                {step === 1 && (
                  <StepOne
                    onHide={onHide}
                    fileList={fileList}
                    setFileList={setFileList}
                    setStep={setStep}
                    step={step}
                    isIntro={isIntro}
                  />
                )}

                {step === 2 && (
                  <M.PostForm className="px-5">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting, values }) => (
                        <Form>
                          <div className="mt-6">
                            <M.UserInfoWrapper>
                              <M.UserImg
                                crossOrigin="anonymous"
                                src={image ? image?.fileURL : DefaultUserImg}
                              />
                              <div className="flex items-center gap-2">
                                <p className="text-white capitalize text-xl mb-0">
                                  {username}
                                </p>
                                <MdVerified className="text-white text-lg" />
                              </div>
                            </M.UserInfoWrapper>
                            <div>
                              <div className="mt-4 relative">
                                <Field
                                  rows={4}
                                  as="textarea"
                                  name="description"
                                  className="w-full p-2 text-xl bg-[#000] text-white rounded resize-none"
                                  placeholder="What's on your mind?"
                                />

                                <M.TextAreaCount className="text-right">
                                  {values.description.length}/2000
                                </M.TextAreaCount>
                              </div>
                              <ErrorMessage
                                name="description"
                                component="p"
                                className="text-red-600 mt-2"
                              />
                              <div className="mt-3 mb-10">
                                <CommonButton
                                  variant="tertary"
                                  className={`w-full ${
                                    isSubmitting ? "opacity-70" : ""
                                  }`}
                                  disabled={isSubmitting}
                                >
                                  {editPost?._id ? "Update" : "Create"} Post
                                </CommonButton>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </M.PostForm>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </M.DragAndDrop>
  );
};

export default CreatePostModal;
